<template>
  <div class="d-inline-block">
    <div :class="getStatusBadge(status)">
      <div class="badge-text-sm">
        {{ getStatus(status) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default : this
    }
  },
  methods: {
    getStatusBadge (status) {
      switch (status) {
        case 'programmed': return 'badge-score-pending-confirmation';
        case 'expired': return 'badge-score-draft-confirmation';
        case 'draft': return 'badge-score-draft-confirmation';
        case 'published': return 'badge-available-success-confirmation';
        case 'rar': return 'badge-available-failed-confirmation';
      }
    },
    getStatus (status) {
      return this.$t("views.news.list.table.status-label." + status);
    },
  }
}
</script>
<style scoped>
.badge-score-draft-confirmation {
  background: #BFC4CC;
  border-radius: 3px;
  opacity: 1;
  color: #3D3D3D;
  width: auto;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}
.badge-score-pending-confirmation {
  background: #F4F0E2;
  border-radius: 3px;
  opacity: 1;
  color: #bca656;
  width: auto;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}
.badge-available-success-confirmation {
  background: #E2F4EC;
  border-radius: 3px;
  opacity: 1;
  color: #43946e;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}
.badge-available-sending-confirmation {
  background: #cae5da;
  border-radius: 3px;
  opacity: 1;
  color: #19b36b;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}
.badge-available-error-confirmation {
  background: #F4E2E2;
  border-radius: 3px;
  opacity: 1;
  color: #E95060;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}
.badge-available-failed-confirmation {
  background: #ecdbdb;
  border-radius: 3px;
  opacity: 1;
  color: #d7747e;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}
</style>
